import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const getCurrency = number =>
  number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

const ChartRationale = ({ data }) => {
  return <HorizontalBar data={data} options={{
    // maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          position: 'top',
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return getCurrency(value);
            }
          }
        }
      ]
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 0,
        bottom: 0
      }
    },
    legend: {
      position: 'top'
    }
  }}
 />;
};

export default ChartRationale;
